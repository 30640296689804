.swal-custom-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.swal-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: .5em;
  display: flex;
}

.custom-title {
  color: var(--white-2);
  text-transform: capitalize;
  font-size: var(--fs-2);
  text-align: center;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.custom-swal-popup {
  background: var(--eerie-black-2);
  border: 1px solid var(--jet);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  border-radius: 20px;
  width: 90%;
  max-width: 1920px;
  padding: 1em;
  overflow: hidden;
}

.responsive-video {
  width: 100%;
  height: auto;
}

.swal2-popup video, .swal2-popup img {
  max-width: 100%;
  height: auto;
}

.swal2-title {
  color: var(--white-2);
  text-transform: capitalize;
  font-size: var(--fs-2);
  margin-bottom: 15px;
  padding: 0;
}

.custom-close-button {
  color: var(--light-gray);
  opacity: 1;
  cursor: pointer;
  margin: 0;
  font-size: 2em;
  position: absolute;
  right: 1em;
}

.swal2-html-container {
  padding: 0 !important;
}

.custom-close-button:hover {
  color: var(--orange-yellow-crayola);
  cursor: pointer;
}

@media (width <= 600px) {
  .custom-swal-popup {
    width: 100%;
    max-width: 90%;
  }
}

@media (width <= 580px) {
  .custom-title {
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
}
/*# sourceMappingURL=index.fb4a9002.css.map */
